import "./Home.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faLinkedinIn,
  faInstagram,
  faGithub,
  faReddit,
  faGoogle,
  faMedium,
} from "@fortawesome/free-brands-svg-icons";
import ReactTypingEffect from "react-typing-effect";

function Home() {
  return (
    <div className="home">
      {/* overlay start */}
      <div className="overlay"></div>
      {/* overlay end */}

      {/* page loader start */}
      <div className="page-loader">
        <div></div>
        <div></div>
        <div></div>
      </div>

      {/* page loader end */}

      {/* background circles start */}

      <div className="bg-circles">
        <div className="circle-1"></div>
        <div className="circle-2"></div>
        <div className="circle-3"></div>
        <div className="circle-4"></div>
      </div>

      {/* background circles end */}

      <div className="main hidden">
        {/* header start */}

        <header className="header">
          <div className="Container">
            <div className="row flex-end">
              <button type="button" className="nav-toggler">
                <span></span>
              </button>
              <nav className="nav">
                <div className="nav-inner">
                  <ul>
                    <li>
                      <a href="#home" className="nav-item link-item">
                        home
                      </a>
                    </li>
                    <li>
                      <a href="#about" className="nav-item link-item">
                        about
                      </a>
                    </li>
                    <li>
                      <a href="#portfolio" className="nav-item link-item">
                        Projects
                      </a>
                    </li>
                    <li>
                      <a href="#contact" className="nav-item link-item">
                        contact
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </header>

        {/* header end */}

        {/* home section start */}
        <section className="home-section align-items-center" id="home">
          <div className="Container">
            <div className="row align-items-center">
              <div className="home-text">
                <p>Hello I'm</p>
                <h1>Jay Chauhan</h1>
                <h2>
                  {" "}
                  <ReactTypingEffect
                    text={[
                      "DevOps Engineer 🚀",
                      "Application Developer",
                      "Tech Geek",
                    ]}
                    speed={100}
                    eraseDelay={900}
                  />
                </h2>
                <div className="contact-info">
                  <div className="contact-info-item">
                    <div className="social-links">
                      <a href="#" target="_blank">
                        {" "}
                        <FontAwesomeIcon icon={faTwitter} />
                      </a>
                      <a
                        href="https://www.linkedin.com/in/jay-chauhan-8a1397170/"
                        target="_blank"
                      >
                        {" "}
                        <FontAwesomeIcon icon={faLinkedinIn} />
                      </a>

                      <a href="https://github.com/jay75chauhan" target="_blank">
                        {" "}
                        <FontAwesomeIcon icon={faGithub} />
                      </a>
                      <a
                        href="https://jay75chauhan.medium.com/"
                        target="_blank"
                      >
                        {" "}
                        <FontAwesomeIcon icon={faMedium} />
                      </a>
                      <a
                        href="https://developers.google.com/profile/u/118044263263659354971"
                        target="_blank"
                      >
                        {" "}
                        <FontAwesomeIcon icon={faGoogle} />
                      </a>
                    </div>
                  </div>
                </div>

                <a href="#about" className="btn link-item">
                  more about me
                </a>
                <a href="#portfolio" className="btn link-item">
                  portfolio
                </a>
                <a
                  href="https://nervous-bassi-d1d66c.netlify.app/"
                  className="btn link-item"
                  target="	_blank"
                >
                  Devloper Card
                </a>
              </div>
              <div className="home-img">
                <div className="img-box">
                  <img src="/images/profile_photo.png" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* home section end */}

        {/* about section start */}

        <section className="about-section sec-padding" id="about">
          <div className="container">
            <div className="row">
              <div className="section-title">
                <h2>about</h2>
              </div>
            </div>
            <div>
              <div className="about-text">
                <p>
                  Hi! I’m Jay, A certified DevOps Engineer with a flair for
                  innovation, I turn cloud complexities into streamlined,
                  automated systems that just work. With AWS and Azure DevOps
                  Engineer certifications under my belt, I’m not just pushing
                  code—I’m securing the future. My passion lies in building
                  bulletproof CI/CD pipelines and scalable infrastructures that
                  stand the test of time. Always ahead of the curve, I merge
                  cutting-edge cloud technology with a relentless focus on
                  security, delivering solutions that are as robust as they are
                  revolutionary.
                </p>
                <h3>skills</h3>
                <div className="skills">
                  <div className="skill-item">Python</div>
                  <div className="skill-item">Bash</div>
                  <div className="skill-item">JavaScript</div>
                  <div className="skill-item">Go</div>
                  <div className="skill-item">Dart</div>
                  <div className="skill-item">React Js</div>
                  <div className="skill-item">Next js</div>
                  <div className="skill-item">Node Js</div>
                  <div className="skill-item">Flutter</div>
                  <div className="skill-item">React Native</div>
                  <div className="skill-item">AWS</div>
                  <div className="skill-item">Azure</div>
                  <div className="skill-item">Nginx</div>
                  <div className="skill-item">Apache</div>
                  <div className="skill-item">MySql</div>
                  <div className="skill-item">postgresql</div>
                  <div className="skill-item">Firebase</div>
                  <div className="skill-item">MongoDb</div>
                  <div className="skill-item">Docker</div>
                  <div className="skill-item">Kubernetes</div>
                  <div className="skill-item">Ansible</div>
                  <div className="skill-item">Terraform</div>
                  <div className="skill-item">Jenkins</div>
                  <div className="skill-item">Istio</div>
                  <div className="skill-item">Azure Devops</div>
                  <div className="skill-item">Prometheus</div>
                  <div className="skill-item">ArgoCd</div>
                  <div className="skill-item">Lens</div>
                  <div className="skill-item">Datree</div>
                  <div className="skill-item">Helm Chart</div>
                  <div className="skill-item">SonarQube</div>
                  <div className="skill-item">Nexus</div>
                  <div className="skill-item">KubeShark</div>
                  <div className="skill-item">GitOps</div>
                  <div className="skill-item">Microsoft Entra ID</div>
                  <div className="skill-item">Jira</div>
                  <div className="skill-item">Linux / Windows Server</div>
                  <div className="skill-item">Git</div>
                </div>
                <div className="about-tabs">
                  <button
                    type="button"
                    className="tab-item active"
                    data-target="#experience"
                  >
                    experience
                  </button>
                  <button
                    type="button"
                    className="tab-item "
                    data-target="#education"
                  >
                    education
                  </button>
                </div>
                {/* Experience start */}
                <div className="tab-content active" id="experience">
                  <div className="timeline">
                    <div className="timeline-item">
                      <span className="date">
                        DevOps Engineer / Application Developer @Empezar
                        Software Labs
                      </span>

                      <h7>
                        Migration of on-premises applications to AWS, leveraging
                        cloud services for improved scalability and resilience,
                        Conducted performance tuning and optimization for both
                        applications and infrastructure, resulting in improved
                        system responsiveness and lower latency Optimized cloud
                        infrastructure by consolidating EKS workloads into ECS,
                        resulting in a 35% reduction in infrastructure costs
                        Revolutionized port logistics with a cutting-edge mobile
                        app built in Flutter, empowering India’s largest port
                        operator
                      </h7>
                    </div>
                    <div className="timeline-item">
                      <span className="date">
                        Software engineering Intern @AtliQ Technologies
                      </span>

                      <h7>
                        Innovative Mobile App Developer delivering seamless,
                        high- performance applications with a focus on
                        cutting-edge design, user experience, and robust
                        functionality
                      </h7>
                    </div>
                  </div>
                </div>
                {/* Experience end */}
                {/* education start */}
                <div className="tab-content " id="education">
                  <div className="timeline">
                    <div className="timeline-item">
                      <span className="date">2018 - 2022</span>
                      <h4>
                        Electronics and Communications Engineering -{" "}
                        <span>Gec-Rajkot</span>
                      </h4>
                      <p></p>
                    </div>
                    <div className="timeline-item">
                      <span className="date">2016 - 2018</span>
                      <h4>
                        11-12th Science - <span>aum vidya mandir-IFFCO</span>
                      </h4>
                      <p></p>
                    </div>
                    <div className="timeline-item">
                      <span className="date">2016</span>
                      <h4>
                        10th - <span>Shree Shantikunj School</span>
                      </h4>
                      <p></p>
                    </div>
                  </div>
                </div>

                {/* education end */}
                <a
                  href="JayChauhanResume.pdf"
                  target="_blank"
                  download
                  className="btn"
                >
                  Download Resume
                </a>
                <a href="#contact" className="btn link-item">
                  contact me
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* about section end */}

        {/* portfolio section start */}

        <section className="portfolio-section sec-padding" id="portfolio">
          <div className="container">
            <div className="row">
              <div className="section-title">
                <h2>Projects</h2>
              </div>
            </div>
            <div className="row">
              {" "}
              {/* portfolio item start */}
              <div className="portfolio-item">
                <div className="portfolio-item-thumbnail">
                  <img src="/images/devops2.png" />
                </div>
                <h3 className="portfolio-item-title">
                  🚀 Streamlined End-to-End DevSecOps Kubernetes Three-Tier
                  Project{" "}
                </h3>
                <button type="button" className="btn view-project-btn">
                  view project
                </button>
                <div className="portfolio-item-details">
                  <div className="description">
                    <p>
                      In this detailed project, you’ll explore setting up a
                      secure, scalable Three-Tier architecture on AWS using
                      Kubernetes, DevOps best practices, and advanced security
                      measures, offering hands-on experience in deploying,
                      securing, and monitoring a robust application environment
                    </p>
                  </div>
                  <div className="general-info">
                    <ul>
                      <li>
                        Tools ⚙ -{" "}
                        <span>
                          AWS EKS, Terraform ,ArgoCD, Prometheus, Grafana, and
                          Jenkins ..{" "}
                        </span>
                      </li>

                      <li>
                        View Online-
                        <span>
                          <a
                            href="https://jay75chauhan.medium.com/streamlined-end-to-end-devsecops-kubernetes-three-tier-project-with-aws-eks-terraform-argocd-0ab73d9de11f"
                            target="_blank"
                          >
                            https://jay75chauhan.medium.com/streamlined-end-to-end-devsecops-kubernetes-three-tier-project-with-aws-eks-terraform-argocd-0ab73d9de11f
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* portfolio item end */}
              {/* portfolio item start */}
              <div className="portfolio-item">
                <div className="portfolio-item-thumbnail">
                  <img src="/images/devops1.png" />
                </div>
                <h3 className="portfolio-item-title">
                  Jenkins CI/CD with Docker| Trivy | Sonarqube | OWASP
                  Dependency Check{" "}
                </h3>
                <button type="button" className="btn view-project-btn">
                  view project
                </button>
                <div className="portfolio-item-details">
                  <div className="description">
                    <p>
                      In this project Jenkins CI/CD pipeline integrates Docker,
                      Trivy, SonarQube, and OWASP Dependency Check to automate,
                      secure, and ensure code quality. Trivy and Dependency
                      Checkhandle vulnerabilities, while SonarQube ensures code
                      integrity, all within Dockerized environments
                    </p>
                  </div>
                  <div className="general-info">
                    <ul>
                      <li>
                        Tools ⚙ -{" "}
                        <span>
                          Jenkins, CI/CD,Docker,Trivy,Sonarqube,OWASP ..{" "}
                        </span>
                      </li>

                      <li>
                        View Online-
                        <span>
                          <a
                            href="https://jay75chauhan.medium.com/jenkins-ci-cd-with-docker-trivy-sonarqube-owasp-dependency-check-fc9b643aef90"
                            target="_blank"
                          >
                            https://jay75chauhan.medium.com/jenkins-ci-cd-with-docker-trivy-sonarqube-owasp-dependency-check-fc9b643aef90
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* portfolio item end */}
              {/* portfolio item start */}
              <div className="portfolio-item">
                <div className="portfolio-item-thumbnail">
                  <img src="/images/amazone.png" />
                </div>
                <h3 className="portfolio-item-title">Amazone 2.0 </h3>
                <button type="button" className="btn view-project-btn">
                  view project
                </button>
                <div className="portfolio-item-details">
                  <div className="description">
                    <p>
                      this project is amazone website inspired design also added
                      strip payment gateway and user order history
                    </p>
                  </div>
                  <div className="general-info">
                    <ul>
                      <li>
                        Tools ⚙ -{" "}
                        <span>
                          next js,web hooks,next-auth,tailwind css ,strip
                          payment API,also mobile screen 📱 responsive ..{" "}
                        </span>
                      </li>

                      <li>
                        View Online-
                        <span>
                          <a
                            href="https://amazone-clone-nextjs.vercel.app/"
                            target="_blank"
                          >
                            https://amazone-clone-nextjs.vercel.app/
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* portfolio item end */}
              {/* portfolio item start */}
              <div className="portfolio-item">
                <div className="portfolio-item-thumbnail">
                  <img src="/images/instagram.png" />
                </div>
                <h3 className="portfolio-item-title">Instagram Clone</h3>
                <button type="button" className="btn view-project-btn">
                  view project
                </button>
                <div className="portfolio-item-details">
                  <div className="description">
                    <p>
                      this projec is clone of instagram website and user like
                      our comment and also upload ther image,also mobile screen
                      📱 responsive
                    </p>
                  </div>
                  <div className="general-info">
                    <ul>
                      <li>
                        Tools ⚙ -{" "}
                        <span>
                          next js,next-auth,tailwind css ,headless UI,custom
                          login page,etc..{" "}
                        </span>
                      </li>

                      <li>
                        View Online-
                        <span>
                          <a
                            href="https://instagram-clone-nextjs.vercel.app/"
                            target="_blank"
                          >
                            https://airbnb-clone-nextjs.vercel.app/
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* portfolio item end */}
              {/* portfolio item start */}
              <div className="portfolio-item">
                <div className="portfolio-item-thumbnail">
                  <img src="/images/airbnb.png" />
                </div>
                <h3 className="portfolio-item-title">airbnb clone</h3>
                <button type="button" className="btn view-project-btn">
                  view project
                </button>
                <div className="portfolio-item-details">
                  <div className="description">
                    <p>
                      this projec is clone of airbnb website and user can select
                      there jurney startin and and date base apone data show the
                      available hotel and ther location,also mobile screen 📱
                      responsive
                    </p>
                  </div>
                  <div className="general-info">
                    <ul>
                      <li>
                        Tools ⚙ -{" "}
                        <span>
                          next js,next-auth,tailwind css
                          ,react-date-range,mapbox,etc..{" "}
                        </span>
                      </li>

                      <li>
                        View Online-
                        <span>
                          <a
                            href="https://airbnb-clone-nextjs.vercel.app/"
                            target="_blank"
                          >
                            https://airbnb-clone-nextjs.vercel.app/
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* portfolio item end */}
              {/* portfolio item start */}
              <div className="portfolio-item">
                <div className="portfolio-item-thumbnail">
                  <img src="/images/spotify.png" />
                </div>
                <h3 className="portfolio-item-title">spotify-next</h3>
                <button type="button" className="btn view-project-btn">
                  view project
                </button>
                <div className="portfolio-item-details">
                  <div className="description">
                    <p>
                      this projec is clone of spotify user can play there
                      spotify playlists using this app ,also mobile screen 📱
                      responsive
                    </p>
                  </div>
                  <div className="general-info">
                    <ul>
                      <li>
                        Tools ⚙ -{" "}
                        <span>
                          next js,next-auth,tailwind css ,nextjs - Middleware
                          spotify API,etc..{" "}
                        </span>
                      </li>

                      <li>
                        View Online-
                        <span>
                          <a
                            href="https://spotify-next-cyan.vercel.app/login"
                            target="_blank"
                          >
                            https://airbnb-clone-nextjs.vercel.app/
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* portfolio item end */}
              {/* portfolio item start */}
              <div className="portfolio-item">
                <div className="portfolio-item-thumbnail">
                  <img src="/images/google-doc.png" />
                </div>
                <h3 className="portfolio-item-title">G-Docs</h3>
                <button type="button" className="btn view-project-btn">
                  view project
                </button>
                <div className="portfolio-item-details">
                  <div className="description">
                    <p>
                      this website functionality like google docs user can edite
                      ther document and save into website and maney more......
                    </p>
                  </div>
                  <div className="general-info">
                    <ul>
                      <li>
                        Tools ⚙ -{" "}
                        <span>
                          Next js,Draf Js,tailwind css,firebase,next auth ..{" "}
                        </span>
                      </li>

                      <li>
                        View Online-
                        <span>
                          <a
                            href="https://google-docs-clone-nextjs-gamma.vercel.app/"
                            target="_blank"
                          >
                            https://google-docs-clone-nextjs-gamma.vercel.app/
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* portfolio item end */}
              {/* portfolio item start */}
              <div className="portfolio-item">
                <div className="portfolio-item-thumbnail">
                  <img src="/images/crypto.png" />
                </div>
                <h3 className="portfolio-item-title">Crypto-Mart</h3>
                <button type="button" className="btn view-project-btn">
                  view project
                </button>
                <div className="portfolio-item-details">
                  <div className="description">
                    <p>
                      this website show all cryptocurrencies details and ther
                      news or price chart history etc....
                    </p>
                  </div>
                  <div className="general-info">
                    <ul>
                      <li>
                        Tools ⚙ -{" "}
                        <span>
                          react js ,ant design,crypto api,reactjs-chart-2..{" "}
                        </span>
                      </li>

                      <li>
                        View Online-
                        <span>
                          <a
                            href="https://crypto-mart-app.vercel.app/"
                            target="_blank"
                          >
                            https://crypto-mart-app.vercel.app/
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* portfolio item end */}
              {/* portfolio item start */}
              <div className="portfolio-item">
                <div className="portfolio-item-thumbnail">
                  <img src="/images/whatShapp.png" />
                </div>
                <h3 className="portfolio-item-title">WhatsApp Clone</h3>
                <button type="button" className="btn view-project-btn">
                  view project
                </button>
                <div className="portfolio-item-details">
                  <div className="description">
                    <p>
                      in this project one-on-one chat message crete chat and
                      user login ther last seen ,also mobile 📱 responsive
                    </p>
                  </div>
                  <div className="general-info">
                    <ul>
                      <li>
                        Tools ⚙ -{" "}
                        <span>
                          next js,tailwind css ,firebase,moment,etc..{" "}
                        </span>
                      </li>

                      <li>
                        View Online-
                        <span>
                          <a
                            href="https://whatsapp-clone-next-mu.vercel.app/"
                            target="_blank"
                          >
                            https://whatsapp-clone-next-mu.vercel.app/
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* portfolio item end */}
              {/* portfolio item start */}
              <div className="portfolio-item">
                <div className="portfolio-item-thumbnail">
                  <img src="/images/netflix.png" />
                </div>
                <h3 className="portfolio-item-title">Netflix Clone</h3>
                <button type="button" className="btn view-project-btn">
                  view project
                </button>
                <div className="portfolio-item-details">
                  <div className="description">
                    <p>
                      netflix clone created you can see movie reviw and also
                      play ther trailare ,also mobile 📱 responsive
                    </p>
                  </div>
                  <div className="general-info">
                    <ul>
                      <li>
                        Tools ⚙ -{" "}
                        <span>
                          reactjs, styledcomponents , vercel , tmdb API
                          ,firebase ,react-reveal ,React-Router
                          ,react-youtube,etc...{" "}
                        </span>
                      </li>

                      <li>
                        View Online-
                        <span>
                          <a
                            href="https://netflix-clone-redux.vercel.app/"
                            target="_blank"
                          >
                            https://netflix-clone-redux.vercel.app/
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* portfolio item end */}
              {/* portfolio item start */}
              <div className="portfolio-item">
                <div className="portfolio-item-thumbnail">
                  <img src="/images/hulu.png" />
                </div>
                <h3 className="portfolio-item-title">Hulu-clone</h3>
                <button type="button" className="btn view-project-btn">
                  view project
                </button>
                <div className="portfolio-item-details">
                  <div className="description">
                    <p>
                      movie information app fully mobile responsive using
                      TailwinCSS
                    </p>
                  </div>
                  <div className="general-info">
                    <ul>
                      <li>
                        Tools ⚙ -{" "}
                        <span>next js,TailwinCSS,heroIcon,etc... </span>
                      </li>

                      <li>
                        View Online-
                        <span>
                          <a
                            href="https://hulu-next-clone-teal.vercel.app/"
                            target="_blank"
                          >
                            https://hulu-next-clone-teal.vercel.app/
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* portfolio item end */}
              {/* portfolio item start */}
              <div className="portfolio-item">
                <div className="portfolio-item-thumbnail">
                  <img src="/images/google.jpg" />
                </div>
                <h3 className="portfolio-item-title">Google-serch clone</h3>
                <button type="button" className="btn view-project-btn">
                  vier project
                </button>
                <div className="portfolio-item-details">
                  <div className="description">
                    <p>
                      in this project you see google search result and also
                      mobile 📱 responsive and #darkmode support, user have mane
                      option to login and there profile manage
                    </p>
                  </div>
                  <div className="general-info">
                    <ul>
                      <li>
                        -{" "}
                        <span>
                          nextjs ,tailwindcss ,heroicon ,googlesearch-API
                          ,vercel,etc... for user management clerk{" "}
                        </span>
                      </li>

                      <li>
                        View Online-
                        <span>
                          <a
                            href="https://google-clone-nextjs-eta.vercel.app/"
                            target="_blank"
                          >
                            https://google-clone-nextjs-eta.vercel.app/
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* portfolio item end */}
              {/* portfolio item start */}
              <div className="portfolio-item">
                <div className="portfolio-item-thumbnail">
                  <img src="/images/faceBook.jpg" />
                </div>
                <h3 className="portfolio-item-title">FaceBook clone</h3>
                <button type="button" className="btn view-project-btn">
                  view project
                </button>
                <div className="portfolio-item-details">
                  <div className="description">
                    <p>
                      this project is clone of facebook chat app you can sed
                      message or image , fully mobile 📱 responsive
                    </p>
                  </div>
                  <div className="general-info">
                    <ul>
                      <li>
                        Tools ⚙ -{" "}
                        <span>
                          nextjs , tailwindcss , heroicon , firebase , nextauth
                          ,google-services-API #facebookfordevloper ,vercel
                          ,etc...
                        </span>
                      </li>

                      <li>
                        View Online-
                        <span>
                          <a
                            href="https://facebook-clone-nextjs-blue.vercel.app/"
                            target="_blank"
                          >
                            https://facebook-clone-nextjs-blue.vercel.app/
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* portfolio item end */}
              {/* portfolio item start */}
              <div className="portfolio-item">
                <div className="portfolio-item-thumbnail">
                  <img src="/images/desney.png" />
                </div>
                <h3 className="portfolio-item-title">
                  Disney + Hoststar Clone
                </h3>
                <button type="button" className="btn view-project-btn">
                  view project
                </button>
                <div className="portfolio-item-details">
                  <div className="description">
                    <p>
                      in this project i am create owen data base for movie
                      details and smooth design created
                    </p>
                  </div>
                  <div className="general-info">
                    <ul>
                      <li>
                        Tools ⚙ -{" "}
                        <span>
                          {" "}
                          reactjs, styledcomponents , vercel , ,firebase
                          ,react-reveal ,,etc...
                        </span>
                      </li>

                      <li>
                        View Online-
                        <span>
                          <a
                            href="https://disney-plus-clone-redux.vercel.app/"
                            target="_blank"
                          >
                            https://disney-plus-clone-redux.vercel.app/
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* portfolio item end */}
            </div>
          </div>
        </section>
        {/* pottfolio section end */}

        {/* contact section start */}

        <section className="contact-section sec-padding" id="contact">
          <div className="container">
            <div className="row">
              <div className="section-title">
                <h2>contact me</h2>
              </div>
            </div>
            <div className="row">
              <div className="contact-form">
                <form>
                  <div className="row">
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder="Name"
                        className="input-control"
                        required
                      />
                    </div>
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder="Email"
                        className="input-control"
                        required
                      />
                    </div>
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder="Subject"
                        className="input-control"
                        required
                      />
                    </div>
                    <div className="input-group">
                      <textarea
                        placeholder="Message"
                        className="input-control"
                        required
                      ></textarea>
                    </div>
                    <div className="submit-btn">
                      <button type="submit" className="btn">
                        send message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="contact-info">
                <div className="contact-info-item">
                  <h3>Email</h3>
                  <p>chauhan75jayk@gmail.com</p>
                </div>
                {/* <div className="contact-info-item">
                  <h3>Phone</h3>
                  <p>+(91)7283862281</p>
                </div> */}
                <div className="contact-info-item">
                  <h3>follow me</h3>
                  <div className="social-links">
                    <a href="#" target="_blank">
                      {" "}
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                    <a href="#" target="_blank">
                      {" "}
                      <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                    <a href="#" target="_blank">
                      {" "}
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href="#" target="_blank">
                      {" "}
                      <FontAwesomeIcon icon={faGithub} />
                    </a>
                    <a href="#" target="_blank">
                      {" "}
                      <FontAwesomeIcon icon={faReddit} />
                    </a>
                    <a href="#" target="_blank">
                      {" "}
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* contact section end */}
      </div>

      {/* main portfolio end*/}

      {/* portfolio item details popup start  */}
      <div className="portfolio-popu">
        <div className="pp-inner">
          <div className="pp-content">
            <div className="pp-header">
              <button type="button" className="btn pp-close">
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <div className="pp-thumbnail">
                <img src="" />
              </div>
              <h3></h3>
            </div>
            <div className="pp-body"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
